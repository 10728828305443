import useScrollPosition from '@react-hook/window-scroll'
import { Trans } from '@lingui/macro'
import { SupportedChainId } from 'constants/chains'
import { useEffect, useState } from 'react'
import { switchToNetwork } from 'utils/switchToNetwork'
import { SITE_URL, APP_URL } from '../../config'
import { useActiveWeb3React } from '../../hooks/web3'
import { Menu as MenuIcon, X as CloseIcon } from 'react-feather'
import Menu from '../Menu'
import Web3Status from '../Web3Status'
import NetworkSelector from './NetworkSelector'
import useMobile from '../../hooks/useMobile'
import { useActiveLocale } from 'hooks/useActiveLocale'

export default function Header() {
  const { account, chainId, library } = useActiveWeb3React()
  const isMobile = useMobile()
  const activeLocale = useActiveLocale()
  const scrollY = useScrollPosition()
  const [showMenu, setShowMenu] = useState(false)

  useEffect(() => {
    if (library && chainId !== SupportedChainId.ARBITRUM_ONE)
      void switchToNetwork({
        library,
        chainId: SupportedChainId.ARBITRUM_ONE,
      })
  }, [chainId, library])

  const calcUrl = (nav: string) => `${APP_URL}${nav}?lang=${activeLocale}`
  if (isMobile) {
    return (
      <>
        <header className="m-header">
          <h1 className="m-header-logo">
            <a href={SITE_URL}>XDX</a>
          </h1>
          <main>
            <Web3Status />
            <MenuIcon onClick={() => setShowMenu(true)} />
          </main>
        </header>
        <div className="m-header-block" />
        <section className={`m-header-menu ${showMenu ? 'active' : ''}`}>
          <div className="m-header-block">
            <CloseIcon onClick={() => setShowMenu(false)} />
          </div>
          {/* <Web3Status /> */}
          <br />
          <Menu mobile />

          <nav className="m-header-nav">
            <a href={calcUrl('trade')}>
              <Trans>NavFutures</Trans>
            </a>
            <a className="active" href="/">
              <Trans>NavSwap</Trans>
            </a>
            {/* <a href={calcUrl('earn')}><Trans>NavEarn</Trans></a> */}
            <a href={calcUrl('launchpad')}>
              <Trans>NavLaunchpad</Trans>
            </a>

            {/*<NavLink onClick={closeMenu} to="/faucets">*/}
            {/*  {t('Header.Nav.Faucet')}*/}
            {/*</NavLink>*/}
          </nav>
          <NetworkSelector />
        </section>
      </>
    )
  }
  return (
    <header className="web-header">
      <main className="web-header-main">
        <h1 className="web-header-logo">
          <a href={SITE_URL}>XDX</a>
        </h1>
        <hr className="web-header-line" />
        <Menu />
        <hr className="web-header-line" />
        <nav className="web-header-nav">
          <a className="active" href="/">
            <Trans>NavSwap</Trans>
          </a>
          <a href={calcUrl('earn')}>
            <Trans>NavEarn</Trans>
          </a>
          <a href={calcUrl('launchpad')}>
            <Trans>NavLaunchpad</Trans>
          </a>
        </nav>
      </main>
      <div className="web-header-tools">
        <NetworkSelector />
        <hr className="web-header-line" />
        <Web3Status />
      </div>
    </header>
  )
}

// import useScrollPosition from '@react-hook/window-scroll'
// import { Trans } from '@lingui/macro'
// import { SupportedChainId } from 'constants/chains'
// import { useEffect } from 'react'
// import styled from 'styled-components/macro'
// import { switchToNetwork } from 'utils/switchToNetwork'
// import { useActiveLocale } from 'hooks/useActiveLocale'

// import { STATIC_URL } from '../../config'
// import { useActiveWeb3React } from '../../hooks/web3'
// import { useWindowSize } from '../../hooks/useWindowSize'
// import Menu from '../Menu'
// import Web3Status from '../Web3Status'
// import NetworkSelector from './NetworkSelector'
// import MobileMenu from './MobileMenu'

// const HeaderFrame = styled.div<{ showBackground: boolean }>`
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   align-items: center;
//   width: 100%;
//   top: 0;
//   position: relative;
//   padding: 0 1rem 0 8px;
//   height: 48px;
//   z-index: 21;
//   position: relative;
//   /* Background slide effect on scroll. */
//   background-image: ${({ theme }) => `linear-gradient(to bottom, transparent 50%, ${theme.bg0} 50% )}}`};
//   background-position: ${({ showBackground }) => (showBackground ? '0 -100%' : '0 0')};
//   background-size: 100% 200%;
//   box-shadow: 0px 0px 0px 1px ${({ theme, showBackground }) => (showBackground ? theme.bg2 : 'transparent;')};
//   transition: background-position 0.1s, box-shadow 0.1s;
//   background-blend-mode: hard-light;
//   background: #1c1c29;
//   box-shadow: 0px -1px 0px 0px #2d2d3e inset;
// `

// const HeaderControls = styled.div`
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   justify-self: flex-end;
// `

// const HeaderElement = styled.div`
//   display: flex;
//   align-items: center;

//   &:not(:first-child) {
//     margin-left: 0.3em;
//   }

//   /* addresses safari's lack of support for "gap" */
//   & > *:not(:first-child) {
//     margin-left: 6px;
//   }

//   ${({ theme }) => theme.mediaWidth.upToMedium`
//     align-items: center;
//   `};
// `

// const AccountElement = styled.div<{ active: boolean }>`
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   background-color: ${({ theme, active }) => (!active ? theme.bg1 : theme.bg1)};
//   border-radius: 12px;
//   white-space: nowrap;
//   width: 100%;

//   :focus {
//     border: 1px solid blue;
//   }
// `
// const HeaderLeft = styled.div`
//   display: flex;
//   align-items: center;
//   gap: 16px;
// `
// const HeaderLeftHr = styled.hr`
//   width: 1px;
//   height: 24px;
//   border: none;
//   background: #2d2d3e;
// `

// const HeaderNavLayout = styled.nav`
//   display: flex;
//   align-items: center;
//   gap: 24px;
// `

// const Logo = styled.a`
//   width: 100px;
//   height: 17px;
//   display: block;
//   background: url(${STATIC_URL}/swap/logo.svg) center/contain no-repeat;
// `

// const NavItem = styled.a`
//   color: #c3c2d4;
//   padding: 10px 12px;
//   // margin: 0 12px;
//   border-radius: 8px;
//   font-size: 14px;

//   &.active {
//     color: #ffffff;
//     background: #303046;
//     // font-weight: bold;
//   }
// `

// export default function Header() {
//   const { account, chainId, library } = useActiveWeb3React()
//   const activeLocale = useActiveLocale()
//   // console.log(activeLocale)
//   const scrollY = useScrollPosition()
//   const size = useWindowSize()

//   useEffect(() => {
//     if (library && chainId !== SupportedChainId.BNB) void switchToNetwork({ library, chainId: SupportedChainId.BNB })
//   }, [chainId, library])

//   const calcUrl = (menu: string) => `https://app.rainbowswap.ai/${menu}?lang=${activeLocale}`

//   return (
//     <HeaderFrame showBackground={scrollY > 45}>
//       <HeaderLeft>
//         <Logo href="https://www.rainbowswap.ai/" />
//         {Number(size.width) > 960 ? (
//           <>
//             <HeaderLeftHr />
//             <Menu />
//             <HeaderLeftHr />

//             <HeaderNavLayout>
//               <NavItem href={calcUrl('trade')}>
//                 <Trans>NavFutures</Trans>
//               </NavItem>
//               <NavItem className="active" href="/swap">
//                 <Trans>NavSwap</Trans>
//               </NavItem>
//               <NavItem href={calcUrl('earn')}>
//                 <Trans>NavEarn</Trans>
//               </NavItem>
//               <NavItem href={calcUrl('launchpad')}>
//                 <Trans>NavLaunchpad</Trans>
//               </NavItem>
//             </HeaderNavLayout>
//           </>
//         ) : null}
//       </HeaderLeft>
//       <HeaderControls>
//         {Number(size.width) > 960 ? (
//           <HeaderElement>
//             <NetworkSelector />
//           </HeaderElement>
//         ) : null}
//         <HeaderElement>
//           <AccountElement active={!!account}>
//             <Web3Status />
//           </AccountElement>
//         </HeaderElement>
//         {Number(size.width) <= 960 ? (
//           <HeaderElement>
//             <MobileMenu />
//           </HeaderElement>
//         ) : null}
//       </HeaderControls>
//     </HeaderFrame>
//   )
// }
