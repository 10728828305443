import { FC } from 'react'
import { Trans } from '@lingui/macro'

import { useActiveWeb3React } from 'hooks/web3'
import { useBlockNumber } from 'state/application/hooks'

import { ExplorerDataType, getExplorerLink } from 'utils/getExplorerLink'

const Footer: FC = () => {
  const { chainId } = useActiveWeb3React()
  const blockNumber = useBlockNumber()

  return (
    <footer className="web-footer">
      <main>
        <a
          className="status connect"
          href={chainId && blockNumber ? getExplorerLink(chainId, blockNumber.toString(), ExplorerDataType.BLOCK) : ''}
        >
          <Trans>FooterOperational</Trans>
        </a>
        <span>RAINBOWSWAP &copy; 2024</span>
        <hr />
      </main>
      <div className="web-footer-links">
        <a href={'https://app.rainbowswap.ai/paper/terms'}>
          <img src="https://static.rainbowswap.ai/image/icon/document.svg" alt="" />
          <Trans>FooterTermsOfUse</Trans>
        </a>
        <a href={'https://app.rainbowswap.ai/paper/privacy'}>
          <img src="https://static.rainbowswap.ai/image/icon/safe.svg" alt="" />
          <Trans>FooterPrivacyPolicy</Trans>
        </a>
      </div>
    </footer>
  )
}

export default Footer
